import React,{ useState, useEffect } from "react";
import Editor from "./Editor"
import useLocalStorage from "../hooks/useLocalStorage";

alert("This is not a final publish for CodeCraft, this publish is just for the testing purposes.\n Thank you for visiting the website.");

function App(){
  const [html, setHtml] =useLocalStorage('html','')
  const [css, setCss] =useLocalStorage('css','')
  const [js, setJs] =useLocalStorage('js','')
  const [srcDoc, setSrcDoc] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() =>{
      setSrcDoc(
        `
  <html>
    <body>${html}</body>
    <style>${css}</style>
    <scrpit>${js}</script>
  </html>
  `
      )
  }, 500)

  return () => clearTimeout(timeout)
}, [html, css, js])


  // const srcDoc =`
  // <html>
  //   <body>${html}</body>
  //   <style>${css}</style>
  //   <scrpit>${js}</script>
  // </html>
  // `


  return(
  <>
  <div className="pane top-pane">
    <Editor 
      language="xml" 
      displayName="HTML" 
      value={html} 
      onChange={setHtml} />
    <Editor 
      language="css" 
      displayName="CSS" 
      value={css} 
      onChange={setCss} />
    <Editor 
      language="javascript" 
      displayName="JS" 
      value={js} 
      onChange={setJs} />

  </div>
  <div classname="pane">
    <iframe
    srcDoc={srcDoc}
      title="output"
      sandbox="allow-scripts"
      frameBorder="0"
      width="100%"
      height="100%"
      />
  </div>
  </>
  )
}

export default App;